import React, { useEffect, useState } from "react";
import classNames from "classnames";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, grayTextColor, yelloColor } from "../../Utils/index";
import AdmissionEnquiry from "views/Admission/AdmissionEnquiry";

const useStyles = makeStyles(styles);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: darkBlueColor,
    fontSize: 18,
    fontFamily: 'roboto',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 16,
    fontFamily: 'roboto',
    fontWeight: 500,
    color: darkBlueColor,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {},
  },
}))(TableRow);

export default function Bsc(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function createData(name, calories) {
    return { name, calories };
  }

  const rows = [
    createData("Duration", "3 Years"),
    createData(
      "Minimum Eligibility",
      "The candidate must have passed 12th with 50% minimum aggregate with any stream."
    ),
    createData(
      "Scholarship",
      "Candidates belonging to reserved categories and economically weaker sections are eligible for state government scholarship schemes."
    ),
    createData(
      "Career Options",
      "Scientist Scientific Assistant Research analyst Teachers Technical Writer/Editor Lecturers Chemist Enumerators Researcher Biostatistician Clinical Research Manager Consultant."
    ),
  ];

  function createBscCbzData(sno, name, feesOne, feesTwo, FeesThree) {
    return { sno, name, feesOne, feesTwo, FeesThree };
  }

  function createBscPcmData(sno, name, feesOne, feesTwo, FeesThree) {
    return { sno, name, feesOne, feesTwo, FeesThree };
  }

  const bscCbzRows = [
    createBscCbzData(1, "ACE FORM & PROSPECTUS FEES", 100, 0, 0),
    createBscCbzData(2, "ACE REGISTRATION FEES", "1,400", 0, 0),
    createBscCbzData(3, "IDENTITY CARD", 100, 0, 0),
    createBscCbzData(4, "BOOK BANK FEES", 800, 800, 800),
    createBscCbzData(5, "CAUTION FEES", "1,000", 0, 0),
    createBscCbzData(6, "UNIVERSITY FEES", 850, 850, 850),
    createBscCbzData(7, "COLLEGE ACTIVITY FEES", 300, 300, 300),
    createBscCbzData(8, "TUITION FEES", "18,450", "20,250", "21,450"),
    createBscCbzData(9, "PRACTICAL", 3000, 3000, 3000),
  ];

  const bscPcmRows = [
    createBscPcmData(1, "ACE FORM & PROSPECTUS FEES", 100, 0, 0),
    createBscPcmData(2, "ACE REGISTRATION FEES", "1,400", 0, 0,),
    createBscPcmData(3, "IDENTITY CARD", 100, 0, 0),
    createBscPcmData(4, "BOOK BANK FEES", 800, 800, 800),
    createBscPcmData(5, "CAUTION FEES", "1,000", 0, 0),
    createBscPcmData(6, "UNIVERSITY FEES", 850, 850, 850),
    createBscPcmData(7, "COLLEGE ACTIVITY FEES", 300, 300, 300),
    createBscPcmData(8, "TUITION FEES", "17,450", "19,150", "20,050"),
    createBscPcmData(9, "PRACTICAL", 2000, 2000, 2000),
  ];

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classNames(classes.main)}
        style={{ marginTop: 110, backgroundColor: "white" }}
      >
        <AdmissionEnquiry
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          courseName={"bsc"}
        />
        <div>
          <div className={classes.container} style={{ padding: 30 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <h2
                  style={{
                    fontSize: "30px",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "bold",
                    color: darkBlueColor,
                    marginBottom: 20,
                  }}
                >
                  Bachelor of Science in Chemistry, Botany, Zoology (BSc-CBZ)
                </h2>

                <div>
                  <p
                    style={{
                      color: grayTextColor,
                      fontSize: 16,
                      fontFamily: "Rubik, sans-serif",
                      textAlign: "justify",
                    }}
                  >
                    This course covers all the aspects of these three fields.
                    In BSc CBZ, there are three courses. One of them is Zoology
                    which focuses on the study of animals; another subject is
                    Botany which focuses on the study of plants and chemistry
                    which focuses on aspects of all life forms. E.g.: genetics
                    and anatomy. This is where the importance of life sciences
                    is felt. Institution ensures that students have imparted
                    quality education and exposed to industrial processes that
                    future proofs their career growth.
                  </p>

                  <h5
                    style={{
                      fontSize: "30px",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "bold",
                    color: darkBlueColor,
                    marginBottom: 20,
                    marginTop: 40,
                    }}
                  >
                    Bachelor of Science in Physics, Chemistry, Mathematics
                    (BSc-PCM)
                  </h5>
                  <p
                    style={{
                      color: grayTextColor,
                      fontSize: 16,
                      fontFamily: "Rubik, sans-serif",
                      textAlign: "justify",
                      marginBottom: 50,
                    }}
                  >
                    This course combines interdisciplinary concepts of three
                    domains hence students can learn those to specialize in any
                    one of the (Physics-Chemistry-Mathematics) domains. The
                    upcoming technological changes are incorporated in the
                    course giving first-hand knowledge. Students who have been
                    very interested in subjects like chemistry, physics, or
                    mathematics, BSc PCM is the best option for them.
                  </p>
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                style={{ marginTop: 30, marginBottom: 30 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Paper style={{ backgroundColor: "#f9f9f9", padding: 15 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography
                            style={{
                              color: "#7b838a",
                              fontFamily: "Rubik, sans-serif",
                            }}
                          >
                            Course Duration
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            style={{
                              color: darkBlueColor,
                              textAlign: "right",
                              fontFamily: "Rubik, sans-serif",
                            }}
                          >
                            3 Years
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      style={{
                        padding: 10,
                        backgroundColor: yelloColor,
                        color: darkBlueColor,
                        fontFamily: "Rubik, sans-serif",
                        fontWeight: 'bold'
                      }}
                      variant="contained"
                      fullWidth
                      onClick={() => setOpenModal(true)}
                    >
                      Enroll the course
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Level</StyledTableCell>
                    <StyledTableCell align="center">
                      Undergraduate
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.calories}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <h2
              style={{
                fontSize: "30px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 40,
                marginTop: 40,
                textAlign: 'center'
              }}
            >
              Fees Structure (JNVU-2023) - BSC (CBZ)
            </h2>

            <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        {/* <StyledTableCell align="left">S. No</StyledTableCell> */}
                        <StyledTableCell align="left">Particular</StyledTableCell>
                        <StyledTableCell align="center">1st Year</StyledTableCell>
                        <StyledTableCell align="center">2nd Year</StyledTableCell>
                        <StyledTableCell align="center">3rd Year</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bscCbzRows?.map((row) => (
                        <StyledTableRow key={row.name}>
                          {/* <StyledTableCell
                            align="left"
                          >
                            {row.sno}
                          </StyledTableCell> */}
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="left"
                          >
                            {row.name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.feesOne}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {row.feesTwo}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {row.FeesThree}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                      <StyledTableRow style={{ backgroundColor: darkBlueColor }}>
                          {/* <StyledTableCell
                            align="left"
                            style={{ 
                              fontSize: 18,
                              color: 'white',
                              fontWeight: 'bold'
                            }}
                          >
                            10
                          </StyledTableCell> */}
                          <StyledTableCell
                            component="th"
                            align="left"
                            style={{ 
                              fontSize: 18,
                              color: 'white',
                              fontWeight: 'bold'
                            }}
                          >
                            TOTAL
                          </StyledTableCell>
                          <StyledTableCell 
                            align="center"
                            style={{ 
                              fontSize: 18,
                              color: 'white',
                              fontWeight: 'bold'
                            }}
                          >
                            26,000
                          </StyledTableCell>

                          <StyledTableCell 
                            align="center"
                            style={{ 
                              fontSize: 18,
                              color: 'white',
                              fontWeight: 'bold'
                            }}
                          >
                            25,200
                          </StyledTableCell>

                          <StyledTableCell 
                            align="center"
                            style={{ 
                              fontSize: 18,
                              color: 'white',
                              fontWeight: 'bold'
                            }}
                          >
                            26,400
                          </StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                <h2
                  style={{
                    fontSize: "30px",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "bold",
                    color: darkBlueColor,
                    marginBottom: 40,
                    marginTop: 40,
                    textAlign: 'center'
                  }}
                >
                  Fees Structure (JNVU-2023) - BSC (PCM)
                </h2>

                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        {/* <StyledTableCell align="left">S. No</StyledTableCell> */}
                        <StyledTableCell align="left">Particular</StyledTableCell>
                        <StyledTableCell align="center">1st Year</StyledTableCell>
                        <StyledTableCell align="center">2nd Year</StyledTableCell>
                        <StyledTableCell align="center">3rd Year</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bscPcmRows?.map((row) => (
                        <StyledTableRow key={row.name}>
                          {/* <StyledTableCell
                            align="left"
                          >
                            {row.sno}
                          </StyledTableCell> */}
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="left"
                          >
                            {row.name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.feesOne}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {row.feesTwo}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {row.FeesThree}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                      <StyledTableRow style={{ backgroundColor: darkBlueColor }}>
                          {/* <StyledTableCell
                            align="left"
                            style={{ 
                              fontSize: 18,
                              color: 'white',
                              fontWeight: 'bold'
                            }}
                          >
                            10
                          </StyledTableCell> */}
                          <StyledTableCell
                            component="th"
                            align="left"
                            style={{ 
                              fontSize: 18,
                              color: 'white',
                              fontWeight: 'bold'
                            }}
                          >
                            TOTAL
                          </StyledTableCell>
                          <StyledTableCell 
                            align="center"
                            style={{ 
                              fontSize: 18,
                              color: 'white',
                              fontWeight: 'bold'
                            }}
                          >
                            24,000
                          </StyledTableCell>

                          <StyledTableCell 
                            align="center"
                            style={{ 
                              fontSize: 18,
                              color: 'white',
                              fontWeight: 'bold'
                            }}
                          >
                            23,100
                          </StyledTableCell>

                          <StyledTableCell 
                            align="center"
                            style={{ 
                              fontSize: 18,
                              color: 'white',
                              fontWeight: 'bold'
                            }}
                          >
                            24,000
                          </StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
