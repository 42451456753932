import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  Breadcrumbs,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import HeaderLinks from "../../components/Header/HeaderLinks";
import Header from "../../components/Header/Header";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, whiteColor, apiUrl } from "../../Utils/index";
import Footer from "components/Footer/Footer";
import ourPrideImage from "assets/img/ourpride.jpg";

const axios = require("axios");
const useStyles = makeStyles(styles);

export default function Placement(props) {
  const history = useHistory();
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: whiteColor,
        }}
        {...rest}
      />
      <div
        className={classNames(classes.main)}
        style={{ marginTop: 110, backgroundColor: whiteColor }}
      >
        <div className={classes.container} style={{ padding: 30 }}>
          <h2
            style={{
              fontSize: "30px",
              fontFamily: "Rubik, sans-serif",
              fontWeight: "bold",
              color: darkBlueColor,
              marginBottom: 20,
            }}
          >
            Placement
          </h2>
        </div>

        <div style={{ padding: 20 }}>
          <img width={"100%"} height={500} src={ourPrideImage} />
        </div>
      </div>
      <Footer />
    </div>
  );
}
