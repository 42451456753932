import React, { useEffect } from "react";
import classNames from "classnames";
import {
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, grayTextColor } from "../../Utils/index";
import libraryImage from "assets/img/library.png";
import BheruSinghLibrary from "assets/img/BheruSinghLibrary.jpg";

const useStyles = makeStyles(styles);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: darkBlueColor,
    fontSize: 18,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 16,
    color: grayTextColor,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {},
  },
}))(TableRow);

export default function Library(props) {
  const classes = useStyles();
  const { ...rest } = props;
  
  const imageStyle = {
    // borderRadius: 20
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function createData(name, calories) {
    return { name, calories };
  }

  const rows = [
    createData("Reference Books", "4000"),
    createData("Text Books", "15000"),
    createData("Competition Books", "300"),
  ];

  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classNames(classes.main)}
        style={{ marginTop: 110, backgroundColor: "white" }}
      >
        <div>
          <div className={classes.container} style={{ padding: 30 }}>
            <h2
              style={{
                fontSize: "30px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 10,
              }}
            >
              Library
            </h2>

            <div style={{ marginBottom: 50, textAlign: 'center' }}>
              <img width={"50%"} height={"50%"} src={libraryImage} style={imageStyle} />
            </div>

            <Grid container spacing={3} style={{ marginBottom: 100 }}>
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <div>
                  <p
                    style={{
                      color: grayTextColor,
                      fontSize: 16,
                      fontFamily: "Rubik, sans-serif",
                      textAlign: "justify",
                    }}
                  >
                    Library is the integral part of any teaching institution and
                    at ACE the prime importance is given to the learner centric
                    atmosphere and innovative skilled approach that comes
                    through wide exposure to books. ACE Library is fully
                    air-cooled and equipped with CCTV.
                  </p>

                  <p
                    style={{
                      color: grayTextColor,
                      fontSize: 16,
                      fontFamily: "Rubik, sans-serif",
                      textAlign: "justify",
                      marginBottom: 40,
                    }}
                  >
                    The library aims to develop a comprehensive collection of
                    documents useful for the faculty as well as students
                    studying at the institution. It contains 19000+ books and
                    magazines for the reference of students.
                  </p>

                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">Books</StyledTableCell>
                          <StyledTableCell align="left">
                            Quantity
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows?.map((row) => (
                          <StyledTableRow key={row.name}>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="left"
                            >
                              {row.name}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.calories}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={3} style={{ textAlign: 'center' }}>
                <img width={250} height={300} src={BheruSinghLibrary} style={imageStyle} />

                <h2
                  style={{
                    fontSize: 20,
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "bold",
                    color: darkBlueColor,
                    marginBottom: -20,
                  }}
                >
                  Bheru Singh Tanwar
                </h2>

                <h2
                  style={{
                    fontSize: 14,
                    fontFamily: "Rubik, sans-serif",
                    color: grayTextColor,
                  }}
                >
                  (Librarian)
                </h2>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
