import React, { useState } from "react";
import {
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
  Button,
  Dialog,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import HeaderLinks from "../../components/Header/HeaderLinks";
import Header from "../../components/Header/Header";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, whiteColor, apiUrl } from "../../Utils/index";
import Footer from "components/Footer/Footer";
import InputText from "components/InputText";

const axios = require("axios");
const useStyles = makeStyles(styles);

export default function Training(props) {
    const history = useHistory();
  const classes = useStyles();
  const { ...rest } = props;
  const [values, setValues] = useState({
    //Personal Details
    firstName: "",
    lastName: "",
    fatherName: "",
    mobileNumber: "",
    emailAddress: "",
    gender: "",
    address: "",
    stateName: "",
    cityName: "",
    pincode: "",

    //Qualification & Education Details
    courseType1: "10th Class",
    courseName1: "10th Class",
    collegeName1: "",
    universityName1: "",
    yop1: "",
    percentage1: "",

    courseType2: "12th Class",
    courseName2: "12th Class",
    collegeName2: "",
    universityName2: "",
    yop2: "",
    percentage2: "",

    courseType3: "Graduation or Diploma",
    courseName3: "",
    collegeName3: "",
    universityName3: "",
    yop3: "",
    percentage3: "",

    courseType4: "Post Graduation",
    courseName4: "",
    collegeName4: "",
    universityName4: "",
    yop4: "",
    percentage4: "",

    //Specialization & Skills
    skills: "",

    //Internship & Experience Details
    companyName1: "",
    designation1: "",
    startDate1: "",
    endDate1: "",

    companyName2: "",
    designation2: "",
    startDate2: "",
    endDate2: "",

    companyName3: "",
    designation3: "",
    startDate3: "",
    endDate3: "",

    companyName4: "",
    designation4: "",
    startDate4: "",
    endDate4: "",

  });
  let [isSubmit, setSubmit] = useState(false);
  const [onSubmit, setOnSubmit] = useState(false);
  const [open, setOpen] = useState(false);
  const [successModalStatus, setSuccessModalStatus] = useState(false);
  const [errorModalStatus, setErrorModalStatus] = useState(false);
  
  const [invaildEmailError, setInvaildEmailError] = useState(false);
  const [invaildMobileError, setInvaildMobileError] = useState(false);

  const validate = () => {
    setSubmit(true);
    let i = (e) => values[e] === "";
    let require = ["fullName", "fatherName", "mobileNumber"];
    let error = require?.map((e) => i(e)).includes(true);

    if (error) return null;
    else ValidateEmail();
  };

  const ValidateEmail = () => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (values.emailAddress.match(mailformat)) {
      ValidateMobile();
      setInvaildEmailError(false);
    } else {
      setInvaildEmailError(true);
      window.scrollTo(0, 0)
    }
  };

  const ValidateMobile = () => {
    if (values.mobileNumber.length > 9) {
      onSubmitForm();
      setInvaildMobileError(false);
    } else {
      setInvaildMobileError(true);
      window.scrollTo(0, 0)
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    setInvaildEmailError(false);
    setInvaildMobileError(false);
  };

  const onSubmitForm = () => {
    setOnSubmit(true);
    setOpen(true);

    axios({
      method: "POST",
      url: apiUrl + "/training",
      headers: {
        "Content-Type": "application/json",
      },
      data: values,
    })
      .then((res) => {
        setOpen(false);
        setSuccessModalStatus(true);
        setOnSubmit(false);
      })
      .catch((error) => {
        setOpen(false);
        setErrorModalStatus(true);
        setOnSubmit(false);
      });
  };

  const handleClose = () => {
    setSuccessModalStatus(false);
    window.location.reload();
  };

  const handleErrorClose = () => {
    setErrorModalStatus(false);
    window.location.reload();
  };

  return (
    <div>
        <Header
            color="white"
            rightLinks={<HeaderLinks />}
            fixed
            changeColorOnScroll={{
            height: 200,
            color: whiteColor,
            }}
            {...rest}
        />
        <div>
          <Backdrop className={classes.backdrop} open={open}>
            <CircularProgress color="inherit" />
          </Backdrop>

          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={successModalStatus}
          >
            <div
              style={{
                paddingLeft: 120,
                paddingRight: 120,
                paddingTop: 20,
                paddingBottom: 20,
                textAlign: "center",
              }}
            >
              <CheckCircleOutlineIcon
                style={{ fontSize: 100, color: "green" }}
              />
              <p
                style={{
                  fontSize: 26,
                  color: "green",
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Success
              </p>

              <p
                style={{
                  fontSize: 16,
                  color: "gray",
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Your request submitted successfully!
              </p>
              <p
                style={{
                  fontSize: 16,
                  color: "gray",
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                We will connect with you ASAP!
              </p>
              <p
                style={{
                  fontSize: 16,
                  color: "gray",
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Thank you!
              </p>
            </div>
          </Dialog>

          <Dialog
            onClose={handleErrorClose}
            aria-labelledby="customized-dialog-title"
            open={errorModalStatus}
          >
            <div
              style={{
                paddingLeft: 120,
                paddingRight: 120,
                paddingTop: 20,
                paddingBottom: 20,
                textAlign: "center",
              }}
            >
              <HighlightOffIcon style={{ fontSize: 100, color: "red" }} />
              <p
                style={{
                  fontSize: 26,
                  color: "red",
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Error
              </p>

              <p
                style={{
                  fontSize: 16,
                  color: "gray",
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                Something went wrong. Please try again!
              </p>
            </div>
          </Dialog>
        </div>
        <div style={{ marginTop: 110, backgroundColor: whiteColor }}>
          <div className={classes.container} style={{ padding: 30 }}>
            <h2
              style={{
                  fontSize: "30px",
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "bold",
                  color: darkBlueColor,
                  marginBottom: 0,
              }}
            >
                Training
            </h2>
          </div>  

          <div style={{ paddingLeft: 40, paddingRight: 40, paddingBottom: 10 }}>
            <div style={{ paddingBottom: 10 }}>
              <Typography style={{
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "bold",
                  color: darkBlueColor,
              }}>Personal Details</Typography>
            </div>
            <Grid container spacing={3} style={{ paddingTop: 10, paddingBottom: 30}} >
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <InputText
                  fullWidth
                  label="First Name"
                  placeholder="Enter Your First Name"
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  error={isSubmit && !values.firstName}
                  helperText={
                    isSubmit && !values.firstName
                      ? "This field is required!"
                      : ""
                  }
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <InputText
                  fullWidth
                  label="Last Name"
                  placeholder="Enter Your Last Name"
                  type="text"
                  name="lastName"
                  value={values.lastName}
                  error={isSubmit && !values.lastName}
                  helperText={
                    isSubmit && !values.lastName
                      ? "This field is required!"
                      : ""
                  }
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <InputText
                  fullWidth
                  label="Father Name"
                  placeholder="Enter Your Father Name"
                  type="text"
                  name="fatherName"
                  value={values.fatherName}
                  error={isSubmit && !values.fatherName}
                  helperText={
                    isSubmit && !values.fatherName
                      ? "This field is required!"
                      : ""
                  }
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-select-small"
                  id="demo-select-small"
                  name="gender"
                  placeholder="Select Your Gender"
                  value={values.gender}
                  label="Age"
                  onChange={handleInputChange}
                  error={isSubmit && !values.gender}
                  helperText={
                    isSubmit && !values.gender
                      ? "This field is required!"
                      : ""
                  }
                >
                  <MenuItem value={'male'}>Male</MenuItem>
                  <MenuItem value={'female'}>Female</MenuItem>
                </Select>

                {/* <InputText
                  fullWidth
                  label="Gender"
                  placeholder="Select Your Gender"
                  type="text"
                  name="gender"
                  value={values.gender}
                  error={isSubmit && !values.gender}
                  helperText={
                    isSubmit && !values.gender
                      ? "This field is required!"
                      : ""
                  }
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                /> */}
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <InputText
                  fullWidth
                  label="Mobile Number"
                  placeholder="Enter Your Mobile Number"
                  type="number"
                  name="mobileNumber"
                  maxLength={10}
                  value={values.mobileNumber}
                  error={isSubmit && (!values.mobileNumber || invaildMobileError)}
                  helperText={
                    isSubmit && !values.mobileNumber
                      ? "This field is required!"
                      : ""
                  }
                  onInput = {(e) =>{
                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+91</InputAdornment>
                  }}
                  onChange={handleInputChange}
                />
                {invaildMobileError && (
                  <span
                    style={{ color: "red", fontSize: 12, marginLeft: 12 }}
                  >
                    Invaild mobile number!
                  </span>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <InputText
                  fullWidth
                  label="Email Address"
                  placeholder="Enter Your Email Address"
                  type="email"
                  name="emailAddress"
                  value={values.emailAddress}
                  error={isSubmit && (!values.emailAddress || invaildEmailError)}
                  helperText={
                    isSubmit && !values.emailAddress
                      ? "This field is required!"
                      : ""
                  }
                  onChange={handleInputChange}
                />
                {invaildEmailError && (
                  <span
                    style={{ color: "red", fontSize: 12, marginLeft: 12 }}
                  >
                    Invaild email address!
                  </span>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={12} lg={12}>
                <InputText
                  fullWidth
                  label="Address"
                  placeholder="Enter Your Full Address"
                  type="text"
                  name="address"
                  value={values.address}
                  multiline
                  rows={4}
                  error={isSubmit && !values.address}
                  helperText={
                    isSubmit && !values.address
                      ? "This field is required!"
                      : ""
                  }
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <InputText
                  fullWidth
                  label="State"
                  placeholder="Select Your State"
                  type="text"
                  name="stateName"
                  value={values.stateName}
                  error={isSubmit && !values.stateName}
                  helperText={
                    isSubmit && !values.stateName
                      ? "This field is required!"
                      : ""
                  }
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <InputText
                  fullWidth
                  label="City"
                  placeholder="Select Your City"
                  type="text"
                  name="cityName"
                  value={values.cityName}
                  error={isSubmit && !values.cityName}
                  helperText={
                    isSubmit && !values.cityName
                      ? "This field is required!"
                      : ""
                  }
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <InputText
                  fullWidth
                  label="Pincode"
                  placeholder="Enter Your Area Pincode"
                  type="number"
                  name="pincode"
                  value={values.pincode}
                  error={isSubmit && !values.pincode}
                  helperText={
                    isSubmit && !values.pincode
                      ? "This field is required!"
                      : ""
                  }
                  onInput = {(e) =>{
                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,6)
                  }}
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </div>

          <div style={{ paddingLeft: 40, paddingRight: 40, paddingBottom: 10 }}>
            <div style={{ paddingBottom: 10 }}>
              <Typography style={{
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "bold",
                  color: darkBlueColor,
              }}>Qualification & Education Details</Typography>
            </div>
            
            <Grid container spacing={3} style={{ paddingTop: 10 }}>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <InputText
                  fullWidth
                  label="Course Type"
                  placeholder="Course Type"
                  type="text"
                  name="courseType1"
                  value={values.courseType1}
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <InputText
                  fullWidth
                  label="School Name"
                  placeholder="Enter Your School Name"
                  type="text"
                  name="collegeName1"
                  value={values.collegeName1}
                  error={isSubmit && !values.collegeName1}
                  helperText={
                    isSubmit && !values.collegeName1
                      ? "This field is required!"
                      : ""
                  }
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <InputText
                  fullWidth
                  label="Board Name"
                  placeholder="Enter Your Board Name"
                  type="text"
                  name="universityName1"
                  value={values.universityName1}
                  error={isSubmit && !values.universityName1}
                  helperText={
                    isSubmit && !values.universityName1
                      ? "This field is required!"
                      : ""
                  }
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2} lg={2}>
                <InputText
                  fullWidth
                  label="Year of Passing"
                  placeholder="Enter Year of Passing"
                  type="number"
                  name="yop1"
                  value={values.yop1}
                  error={isSubmit && !values.yop1}
                  helperText={
                    isSubmit && !values.yop1
                      ? "This field is required!"
                      : ""
                  }
                  onInput = {(e) =>{
                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,4)
                  }}
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2} lg={2}>
                <InputText
                  fullWidth
                  label="Percentage"
                  placeholder="Enter Precentage"
                  type="number"
                  name="percentage1"
                  value={values.percentage1}
                  error={isSubmit && !values.percentage1}
                  helperText={
                    isSubmit && !values.percentage1
                      ? "This field is required!"
                      : ""
                  }
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} style={{ paddingTop: 10 }}>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <InputText
                  fullWidth
                  label="Course Type"
                  placeholder="Course Type"
                  type="text"
                  name="courseType2"
                  value={values.courseType2}
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <InputText
                  fullWidth
                  label="School Name"
                  placeholder="Enter Your School Name"
                  type="text"
                  name="collegeName2"
                  value={values.collegeName2}
                  error={isSubmit && !values.collegeName2}
                  helperText={
                    isSubmit && !values.collegeName2
                      ? "This field is required!"
                      : ""
                  }
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <InputText
                  fullWidth
                  label="Board Name"
                  placeholder="Enter Your Board Name"
                  type="text"
                  name="universityName2"
                  value={values.universityName2}
                  error={isSubmit && !values.universityName2}
                  helperText={
                    isSubmit && !values.universityName2
                      ? "This field is required!"
                      : ""
                  }
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2} lg={2}>
                <InputText
                  fullWidth
                  label="Year of Passing"
                  placeholder="Enter Year of Passing"
                  type="number"
                  name="yop2"
                  value={values.yop2}
                  error={isSubmit && !values.yop2}
                  helperText={
                    isSubmit && !values.yop2
                      ? "This field is required!"
                      : ""
                  }
                  onInput = {(e) =>{
                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,4)
                  }}
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2} lg={2}>
                <InputText
                  fullWidth
                  label="Percentage"
                  placeholder="Enter Precentage"
                  type="number"
                  name="percentage2"
                  value={values.percentage2}
                  error={isSubmit && !values.percentage2}
                  helperText={
                    isSubmit && !values.percentage2
                      ? "This field is required!"
                      : ""
                  }
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} style={{ paddingTop: 10 }}>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <InputText
                  fullWidth
                  label="Course Type"
                  placeholder="Course Type"
                  type="text"
                  name="courseType3"
                  value={values.courseType3}
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2} lg={2}>
                <InputText
                  fullWidth
                  label="Course Name"
                  placeholder="Enter Course Name"
                  type="text"
                  name="courseName3"
                  value={values.courseName3}
                  error={isSubmit && !values.courseName3}
                  helperText={
                    isSubmit && !values.courseName3
                      ? "This field is required!"
                      : ""
                  }
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2} lg={2}>
                <InputText
                  fullWidth
                  label="College Name"
                  placeholder="Enter College Name"
                  type="text"
                  name="collegeName3"
                  value={values.collegeName3}
                  error={isSubmit && !values.collegeName3}
                  helperText={
                    isSubmit && !values.collegeName3
                      ? "This field is required!"
                      : ""
                  }
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2} lg={2}>
                <InputText
                  fullWidth
                  label="University Name"
                  placeholder="Enter Your University Name"
                  type="text"
                  name="universityName3"
                  value={values.universityName3}
                  error={isSubmit && !values.universityName3}
                  helperText={
                    isSubmit && !values.universityName3
                      ? "This field is required!"
                      : ""
                  }
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2} lg={2}>
                <InputText
                  fullWidth
                  label="Year of Passing"
                  placeholder="Enter Year of Passing"
                  type="number"
                  name="yop3"
                  value={values.yop3}
                  error={isSubmit && !values.yop3}
                  helperText={
                    isSubmit && !values.yop3
                      ? "This field is required!"
                      : ""
                  }
                  onInput = {(e) =>{
                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,4)
                  }}
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2} lg={2}>
                <InputText
                  fullWidth
                  label="Percentage"
                  placeholder="Enter Precentage"
                  type="number"
                  name="percentage3"
                  value={values.percentage3}
                  error={isSubmit && !values.percentage3}
                  helperText={
                    isSubmit && !values.percentage3
                      ? "This field is required!"
                      : ""
                  }
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} style={{ paddingTop: 10 }}>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <InputText
                  fullWidth
                  label="Course Type"
                  placeholder="Course Type"
                  type="text"
                  name="courseType4"
                  value={values.courseType4}
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2} lg={2}>
                <InputText
                  fullWidth
                  label="Course Name"
                  placeholder="Enter Course Name"
                  type="text"
                  name="courseName4"
                  value={values.courseName4}
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2} lg={2}>
                <InputText
                  fullWidth
                  label="College Name"
                  placeholder="Enter College Name"
                  type="text"
                  name="collegeName4"
                  value={values.collegeName4}
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2} lg={2}>
                <InputText
                  fullWidth
                  label="University Name"
                  placeholder="Enter Your University Name"
                  type="text"
                  name="universityName4"
                  value={values.universityName4}
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2} lg={2}>
                <InputText
                  fullWidth
                  label="Year of Passing"
                  placeholder="Enter Year of Passing"
                  type="number"
                  name="yop4"
                  value={values.yop4}
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onInput = {(e) =>{
                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,4)
                  }}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2} lg={2} style={{ paddingBottom: 30 }}>
                <InputText
                  fullWidth
                  label="Percentage"
                  placeholder="Enter Precentage"
                  type="number"
                  name="percentage4"
                  value={values.percentage4}
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>            
          </div>

          <div style={{ paddingLeft: 40, paddingRight: 40}}>
            <div style={{ paddingBottom: 10 }}>
              <Typography style={{
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "bold",
                  color: darkBlueColor,
              }}>Specialization & Skills</Typography>
            </div>
            <Grid container spacing={3} style={{ paddingTop: 5, paddingBottom: 30}} >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <InputText
                  fullWidth
                  label="Skills"
                  placeholder="Java, C, Web Developement, Javascript"
                  type="text"
                  name="skills"
                  value={values.skills}
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </div>

          <div style={{ paddingLeft: 40, paddingRight: 40, paddingBottom: 10 }}>
            <div style={{ paddingBottom: 10 }}>
              <Typography style={{
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "bold",
                  color: darkBlueColor,
              }}>Internship & Experience Details</Typography>
            </div>
            <Grid container spacing={3} style={{ paddingTop: 5}} >
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <InputText
                  fullWidth
                  label="Company Name"
                  placeholder="Enter Company Name"
                  type="text"
                  name="companyName1"
                  value={values.companyName1}
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <InputText
                  fullWidth
                  label="Designation"
                  placeholder="Enter Designation"
                  type="text"
                  name="designation1"
                  value={values.designation1}
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <InputText
                  fullWidth
                  label="Start Date"
                  placeholder="Enter Start Date"
                  type="text"
                  name="startDate1"
                  value={values.startDate1}
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <InputText
                  fullWidth
                  label="End Date"
                  placeholder="Enter End Date"
                  type="text"
                  name="endDate1"
                  value={values.endDate1}
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} style={{ paddingTop: 10}} >
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <InputText
                  fullWidth
                  label="Company Name"
                  placeholder="Enter Company Name"
                  type="text"
                  name="companyName2"
                  value={values.companyName2}
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <InputText
                  fullWidth
                  label="Designation"
                  placeholder="Enter Designation"
                  type="text"
                  name="designation2"
                  value={values.designation2}
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <InputText
                  fullWidth
                  label="Start Date"
                  placeholder="Enter Start Date"
                  type="text"
                  name="startDate2"
                  value={values.startDate2}
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <InputText
                  fullWidth
                  label="End Date"
                  placeholder="Enter End Date"
                  type="text"
                  name="endDate2"
                  value={values.endDate2}
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} style={{ paddingTop: 10}} >
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <InputText
                  fullWidth
                  label="Company Name"
                  placeholder="Enter Company Name"
                  type="text"
                  name="companyName3"
                  value={values.companyName3}
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <InputText
                  fullWidth
                  label="Designation"
                  placeholder="Enter Designation"
                  type="text"
                  name="designation3"
                  value={values.designation3}
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <InputText
                  fullWidth
                  label="Start Date"
                  placeholder="Enter Start Date"
                  type="text"
                  name="startDate3"
                  value={values.startDate3}
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <InputText
                  fullWidth
                  label="End Date"
                  placeholder="Enter End Date"
                  type="text"
                  name="endDate3"
                  value={values.endDate3}
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} style={{ paddingTop: 10, marginBottom: 30}} >
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <InputText
                  fullWidth
                  label="Company Name"
                  placeholder="Enter Company Name"
                  type="text"
                  name="companyName4"
                  value={values.companyName4}
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <InputText
                  fullWidth
                  label="Designation"
                  placeholder="Enter Designation"
                  type="text"
                  name="designation4"
                  value={values.designation4}
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <InputText
                  fullWidth
                  label="Start Date"
                  placeholder="Enter Start Date"
                  type="text"
                  name="startDate4"
                  value={values.startDate4}
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} lg={3}>
                <InputText
                  fullWidth
                  label="End Date"
                  placeholder="Enter End Date"
                  type="text"
                  name="endDate4"
                  value={values.endDate4}
                  inputProps={{style: {textTransform: 'capitalize'}}}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>

            <div style={{ textAlign: "right", marginBottom: 20, marginTop: 10 }}>
                <Button
                  variant="contained"
                  onClick={() => validate()}
                  disabled={onSubmit}
                  style={{
                    backgroundColor: darkBlueColor,
                    color: "white",
                    textTransform: 'none',
                    height: '100%'
                  }}
                >
                  Submit
                </Button>
              </div>
          </div>
        </div>
      <Footer/>
    </div>
  )
}