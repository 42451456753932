/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
// react components for routing our app without refresh

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  ListItem,
  Tooltip,
  List,
  Grow,
  Popper,
  Paper,
  Menu,
  MenuItem,
  MenuList,
  ListSubheader,
  Collapse,
  ListItemIcon,
  ListItemText,
  ClickAwayListener
} from "@material-ui/core";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Popover from "@material-ui/core/Popover";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import pdf from "../../assets/img/Ace_Prospectus.pdf";
import image from "../../assets/img/logo.png";

// @material-ui/icons
import { CloudDownload } from "@material-ui/icons";

// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { useHistory, NavLink } from "react-router-dom";
import AdmissionEnquiry from "views/Admission/AdmissionEnquiry";
import { darkBlueColor } from "Utils";

const useStyles = makeStyles(styles);
// const useStyles = makeStyles((styles) => ({
//   deleteIcon1: {
//     '& svg': {
//       fontSize: 25
//     }
//   },
//   largeIcon: {
//     width: 60,
//     height: 60,
//     backgroundColor: 'red'
//   },
// }));

export default function HeaderLinks(props) {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [anchorElLeft, setAnchorElLeft] = useState(null);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [admissionOpen, setAdmissionOpen] = useState(false);
  const [campusOpen, setCampusOpen] = useState(false);
  const [departmentOpen, setDepartmentOpen] = useState(false);
  const [studentCornerOpen, setStudentCornerOpen] = useState(false);
  const [TPOOpen, setTPOOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [ismobile, setIsMobile] = useState(false);

  const anchorRef = useRef(null);

  const prevAboutOpen = useRef(aboutOpen);
  const aboutRef = useRef(null);

  const prevAdmissionOpen = useRef(admissionOpen);
  const admissionRef = useRef(null);

  const prevCampusOpen = useRef(campusOpen);
  const campusRef = useRef(null);

  const prevDepartmentOpen = useRef(departmentOpen);
  const departmentRef = useRef(null);

  const prevStudentCornerOpen = useRef(studentCornerOpen);
  const studentCornerRef = useRef(null);

  const prevTPOOpen = useRef(TPOOpen);
  const tpoRef = useRef(null);

  //Dropdown Menu start
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;

    if (prevAboutOpen.current === true && aboutOpen === false) {
      aboutRef.current.focus();
    }
    prevAboutOpen.current = aboutOpen;

    if (prevAdmissionOpen.current === true && admissionOpen === false) {
      admissionRef.current.focus();
    }
    prevAdmissionOpen.current = admissionOpen;

    if (prevCampusOpen.current === true && campusOpen === false) {
      campusRef.current.focus();
    }
    prevCampusOpen.current = campusOpen;

    if (prevDepartmentOpen.current === true && departmentOpen === false) {
      departmentRef.current.focus();
    }
    prevDepartmentOpen.current = departmentOpen;

    if (prevStudentCornerOpen.current === true && studentCornerOpen === false) {
      studentCornerRef.current.focus();
    }
    prevStudentCornerOpen.current = studentCornerOpen;

    if (prevTPOOpen.current === true && TPOOpen === false) {
      tpoRef.current.focus();
    }
    prevTPOOpen.current = TPOOpen;

  }, [open, aboutOpen, admissionOpen, campusOpen, departmentOpen, studentCornerOpen]);
  //Dropdown menu end

  const handleAboutToggle = () => {
    setAboutOpen((prevAboutOpen) => !prevAboutOpen);
  };

  const handleAboutClose = (event) => {
    if (aboutRef.current && aboutRef.current.contains(event.target)) {
      return;
    }
    setAboutOpen(false);
  };

  function handleAboutListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setAboutOpen(false);
    }
  }

  //Dropdown AdmissionOpen Menu start
  const handleAdmissionToggle = () => {
    setAdmissionOpen((prevAdmissionOpen) => !prevAdmissionOpen);
  };

  const handleAdmissionClose = (event) => {
    if (admissionRef.current && admissionRef.current.contains(event.target)) {
      return;
    }
    setAdmissionOpen(false);
  };

  function handleAdmissionListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setAdmissionOpen(false);
    }
  }
  //Dropdown AdmissionOpen Menu end

  //Dropdown campusOpen Menu start
  const handleCampusToggle = () => {
    setCampusOpen((prevCampusOpen) => !prevCampusOpen);
  };

  const handleCampusClose = (event) => {
    if (campusRef.current && campusRef.current.contains(event.target)) {
      return;
    }
    setCampusOpen(false);
  };

  function handleCampusListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setCampusOpen(false);
    }
  }
  //Dropdown campusOpen Menu end

  //Dropdown Department Menu start
  const handleDepartmentToggle = () => {
    setDepartmentOpen((prevDepartmentOpen) => !prevDepartmentOpen);
  };

  const handleDepartmentClose = (event) => {
    if (departmentRef.current && departmentRef.current.contains(event.target)) {
      return;
    }
    setDepartmentOpen(false);
  };

  function handleDepartmentListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setDepartmentOpen(false);
    }
  }
  //Dropdown Department Menu end

  //Dropdown student corner Menu start
  const handleStudentCornerToggle = () => {
    setStudentCornerOpen((prevStudentCornerOpen) => !prevStudentCornerOpen);
  };

  const handleStudentCornerClose = (event) => {
    if (studentCornerRef.current && studentCornerRef.current.contains(event.target)) {
      return;
    }
    setStudentCornerOpen(false);
  };

  function handleStudentCornerListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setStudentCornerOpen(false);
    }
  }
  //Dropdown Student corner Menu end

  //Dropdown TPO Menu start
  const handleTPOToggle = () => {
    setTPOOpen((prevTPOOpen) => !prevTPOOpen);
  };

  const handleTPOClose = (event) => {
    if (tpoRef.current && tpoRef.current.contains(event.target)) {
      return;
    }
    setTPOOpen(false);
  };

  function handleTPOListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setTPOOpen(false);
    }
  }
  //Dropdown TPO end

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    var isMobile = window.orientation > -1;
    setIsMobile(isMobile);
  }, []);

  const [aboutUsOpen, setAboutUsOpen] = useState(false);
  const [academicsOpen, setAcademicsOpen] = useState(false);
  const [admissionSectionOpen, setAdmissionSectionOpen] = useState(false);
  const [campusSectionOpen, setCampusSectionOpen] = useState(false);
  const [departmentSectionOpen, setDepartmentSectionOpen] = useState(false);
  const [studentCornerSectionOpen, setStudentCornerSectionOpen] = useState(false);
  const [tpoSectionOpen, setTpoSectionOpen] = useState(false);

  const onHandleAboutUsSection = () => {
    setAboutUsOpen(!aboutUsOpen);
    setAcademicsOpen(false);
    setAdmissionSectionOpen(false);
    setCampusSectionOpen(false);
    setDepartmentSectionOpen(false);
    setStudentCornerSectionOpen(false);
    setTpoSectionOpen(false);
  };

  const onHandleAcademicsSection = () => {
    setAcademicsOpen(!academicsOpen);
    setAboutUsOpen(false);
    setAdmissionSectionOpen(false);
    setCampusSectionOpen(false);
    setDepartmentSectionOpen(false);
    setStudentCornerSectionOpen(false);
    setTpoSectionOpen(false);
  };

  const onHandleAdmissionSection = () => {
    setAdmissionSectionOpen(!admissionSectionOpen);
    setAboutUsOpen(false);
    setAcademicsOpen(false);
    setCampusSectionOpen(false);
    setDepartmentSectionOpen(false);
    setStudentCornerSectionOpen(false);
    setTpoSectionOpen(false);
  };

  const onHandleCampusSection = () => {
    setCampusSectionOpen(!campusSectionOpen);
    setAboutUsOpen(false);
    setAcademicsOpen(false);
    setAdmissionSectionOpen(false);
    setDepartmentSectionOpen(false);
    setStudentCornerSectionOpen(false);
    setTpoSectionOpen(false);
  };

  const onHandleDepartmentSection = () => {
    setDepartmentSectionOpen(!departmentSectionOpen);
    setAboutUsOpen(false);
    setAcademicsOpen(false);
    setAdmissionSectionOpen(false);
    setCampusSectionOpen(false);
    setStudentCornerSectionOpen(false);
    setTpoSectionOpen(false);
  };

  const onHandleStudentCornerSection = () => {
    setStudentCornerSectionOpen(!studentCornerSectionOpen);
    setAboutUsOpen(false);
    setAcademicsOpen(false);
    setAdmissionSectionOpen(false);
    setCampusSectionOpen(false);
  };

  const onHandleTPOSection = () => {
    setTpoSectionOpen(!tpoSectionOpen);
    setAboutUsOpen(false);
    setAcademicsOpen(false);
    setAdmissionSectionOpen(false);
    setCampusSectionOpen(false);
    setStudentCornerSectionOpen(false);
  };

  return (
    <>
    {ismobile ?   
       <List
          style={{ paddingLeft: 0, paddingRight: 0 }}
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'red' }}
          component="nav"
        >
        <div style={{ textAlign: 'center', marginBottom: 20, marginTop: -10}}>
        <img 
          src={image}
          alt="ACE" 
          height={80}
          className={classes.imgRounded + " " + classes.imgFluid}
          onClick={()=> history.push('/')}
        />
        </div>

        {/* About Us Section Start*/}
        <Button style={{ width: '100%', backgroundColor: "#fdc632", height: 50 }} onClick={()=> onHandleAboutUsSection()}>
          <ListItemText style={{ textTransform: 'none', color: darkBlueColor, fontWeight: 'bold' }} primary="About Us" />
          {aboutUsOpen ? <ExpandLess style={{ color: darkBlueColor, width: 25, height: 25 }} /> : <ExpandMore style={{ color: darkBlueColor, width: 25, height: 25 }}/>}
        </Button>

        <Collapse in={aboutUsOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Button
              onClick={() => history.push("/about-us")} 
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="About Ace" />
            </Button>

            <Button 
              onClick={() => history.push("/director-message")} 
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="Director Message" />
            </Button>

            <Button 
              onClick={() => history.push("/chairman-message")}
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="Chairman Message" />
            </Button>

            <Button 
              onClick={() => history.push("/administration")}
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="Administration" />
            </Button>
          </List>
        </Collapse>
        {/* About Us Section End*/}

        {/* Academics Section Start */}
        <Button style={{ width: '100%', backgroundColor: "#fdc632", height: 50 }} onClick={()=> onHandleAcademicsSection()}>
          <ListItemText style={{ textTransform: 'none', color: darkBlueColor, fontWeight: 'bold' }} primary="Academics" />
          {academicsOpen ? <ExpandLess style={{ color: darkBlueColor, width: 25, height: 25 }}/> : <ExpandMore style={{ color: darkBlueColor, width: 25, height: 25 }}/>}
        </Button>

        <Collapse in={academicsOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Button
              onClick={() => history.push("/bca")} 
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="BCA" />
            </Button>

            <Button 
              onClick={() => history.push("/ba")} 
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="BA" />
            </Button>

            <Button 
              onClick={() => history.push("/bcom")}
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="B.COM" />
            </Button>

            <Button 
              onClick={() => history.push("/bsc")}
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="BSC (PCM-CBZ)" />
            </Button>
          </List>
        </Collapse>
        {/* Academics Section End */}

        {/* Admission Section Start */}
        <Button style={{ width: '100%', backgroundColor: "#fdc632", height: 50 }} onClick={()=> onHandleAdmissionSection()}>
          <ListItemText style={{ textTransform: 'none', color: darkBlueColor, fontWeight: 'bold' }} primary="Academics" />
          {admissionSectionOpen ? <ExpandLess style={{ color: darkBlueColor, width: 25, height: 25 }}/> : <ExpandMore style={{ color: darkBlueColor, width: 25, height: 25 }}/>}
        </Button>

        <Collapse in={admissionSectionOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Button
              onClick={() => history.push("/fee-structure")}
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="Academic Fee" />
            </Button>

            <Button 
              onClick={() => window.location.href=pdf}
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="College Brochure" />
            </Button>

            {/* <Button 
              onClick={() => {
                // setAdmissionSectionOpen(false);
                setOpenModal(true);
              }}
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="Admission Enquiry" />
            </Button> */}
          </List>
        </Collapse>
        {/* Admission Section End */}

        {/* Campus Section Start */}
        <Button style={{ width: '100%', backgroundColor: "#fdc632", height: 50 }} onClick={()=> onHandleCampusSection()}>
          <ListItemText style={{ textTransform: 'none', color: darkBlueColor, fontWeight: 'bold' }} primary="Campus" />
          {campusSectionOpen ? <ExpandLess style={{ color: darkBlueColor, width: 25, height: 25 }}/> : <ExpandMore style={{ color: darkBlueColor, width: 25, height: 25 }}/>}
        </Button>

        <Collapse in={campusSectionOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Button
              onClick={() => history.push("/library")}
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="Library" />
            </Button>

            <Button 
              onClick={() => history.push("/computer-lab")}
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="Computer Lab" />
            </Button>

            <Button 
              onClick={() => history.push("/chemistry-lab")}
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="Chemistry Lab" />
            </Button>

            <Button 
              onClick={() => history.push("/physics-lab")}
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="Physics Lab" />
            </Button>
            
            <Button 
              onClick={() => history.push("/digital-electronics-lab")}
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="Digital Electronics Lab" />
            </Button>

            <Button 
              onClick={() => history.push("/zoology-lab")}
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="Zoology Lab" />
            </Button>

            <Button 
              onClick={() => history.push("/botany-lab")}
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="Botany Lab" />
            </Button>

            <Button 
              onClick={() => history.push("/lecture-hall")}
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="Lecture Hall" />
            </Button>

            <Button 
              onClick={() => history.push("/sports-activities")}
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="Sports Activities" />
            </Button>

            <Button 
              onClick={() => history.push("/cultural-activities")}
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="Cultural Activities" />
            </Button>

            <Button 
              onClick={() => history.push("/industrial-visit")}
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="Industrial Visit" />
            </Button>            
          </List>
        </Collapse>
        {/* Campus Section End */}

        {/* Departments Section Start */}
        <Button style={{ width: '100%', backgroundColor: "#fdc632", height: 50 }} onClick={()=> onHandleDepartmentSection()}>
          <ListItemText style={{ textTransform: 'none', color: darkBlueColor, fontWeight: 'bold' }} primary="Departments" />
          {departmentSectionOpen ? <ExpandLess style={{ color: darkBlueColor, width: 25, height: 25 }}/> : <ExpandMore style={{ color: darkBlueColor, width: 25, height: 25 }}/>}
        </Button>

        <Collapse in={departmentSectionOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Button
              onClick={() => history.push("/computer-science")} 
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="Computer Science" />
            </Button>

            <Button 
              onClick={() => history.push("/science")} 
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="Science" />
            </Button>

            <Button 
              onClick={() => history.push("/arts")}
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="Arts" />
            </Button>

            <Button 
              onClick={() => history.push("/commerce")}
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="Commerce" />
            </Button>
          </List>
        </Collapse>
        {/* Departments Section End */}

        {/* Student Corner Section Start */}
        <Button style={{ width: '100%', backgroundColor: "#fdc632", height: 50 }} onClick={()=> onHandleStudentCornerSection()}>
          <ListItemText style={{ textTransform: 'none', color: darkBlueColor, fontWeight: 'bold' }} primary="Student Corner" />
          {studentCornerSectionOpen ? <ExpandLess style={{ color: darkBlueColor, width: 25, height: 25 }}/> : <ExpandMore style={{ color: darkBlueColor, width: 25, height: 25 }}/>}
        </Button>

        <Collapse in={studentCornerSectionOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Button
              onClick={() => history.push("/assignment")} 
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="Assignment" />
            </Button>

            <Button 
              onClick={() => history.push("/previous-paper")} 
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="Previous Paper" />
            </Button>
          </List>
        </Collapse>
        {/* Student Corner Section End */}

        {/* TPO Section Start */}
        <Button style={{ width: '100%', backgroundColor: "#fdc632", height: 50 }} onClick={()=> onHandleTPOSection()}>
          <ListItemText style={{ textTransform: 'none', color: darkBlueColor, fontWeight: 'bold' }} primary="TPO" />
          {tpoSectionOpen ? <ExpandLess style={{ color: darkBlueColor, width: 25, height: 25 }}/> : <ExpandMore style={{ color: darkBlueColor, width: 25, height: 25 }}/>}
        </Button>

        <Collapse in={tpoSectionOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Button
              onClick={() => history.push("/training")} 
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="Training" />
            </Button>

            <Button 
              onClick={() => history.push("/placement")} 
              style={{ width: '100%', backgroundColor: darkBlueColor, height: 50 }}
            >
              <ListItemText style={{ textTransform: 'none' }} primary="Placement" />
            </Button>
          </List>
        </Collapse>
        {/* TPO Section End */}

        <Button style={{ width: '100%', backgroundColor: "#fdc632", height: 50 }} onClick={() => history.push("/alumni")}>
          <ListItemText style={{ textTransform: 'none', color: darkBlueColor, fontWeight: 'bold' }} primary="Alumni" />
        </Button>

        <Button style={{ width: '100%', backgroundColor: "#fdc632", height: 50 }} onClick={() => history.push("/gallery")}>
          <ListItemText style={{ textTransform: 'none', color: darkBlueColor, fontWeight: 'bold' }} primary="Gallery" />
        </Button>

        <Button style={{ width: '100%', backgroundColor: "#fdc632", height: 50 }} onClick={() => history.push("/contact-us")}>
          <ListItemText style={{ textTransform: 'none', color: darkBlueColor, fontWeight: 'bold' }} primary="Contact Us" />
        </Button>
      </List>
    :
      <List className={classes.list}>
        <AdmissionEnquiry
          openModal={openModal}
          handleCloseModal={handleCloseModal}
        />
        <div style={{ marginBottom: 30 }}></div>

        <ListItem
          onMouseEnter={handleAboutToggle}
          onMouseLeave={handleAboutToggle}
          className={classes.listItem}
        >
          <div>
            <span
              ref={aboutRef}
              aria-controls={aboutOpen ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              style={{
                padding: 15,
                fontWeight: "bold",
                alignItems: "center",
                cursor: "pointer",
                color: "black",
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
              }}
              onMouseEnter={(e) => (
                (e.target.style.color = "#ffffff"),
                (e.target.style.backgroundColor = "#fdc632")
              )}
              onMouseLeave={(e) => (
                (e.target.style.color = "#000000"),
                (e.target.style.backgroundColor = "#ffffff")
              )}
            >
              About Us
            </span>
            {/* <Button style={{ color: 'none' }} color="primary" endIcon={<ExpandMoreIcon>primary</ExpandMoreIcon>}>Primary</Button> */}
            <Popper
              open={aboutOpen}
              anchorEl={aboutRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleAboutClose}>
                      <MenuList
                        id="menu-list-grow"
                        onKeyDown={handleAboutListKeyDown}
                      >
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/about-us")}
                        >
                          About ACE 
                        </MenuItem>
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/director-message")}
                        >
                          Director Message
                        </MenuItem>
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/chairman-message")}
                        >
                          Chairman Message
                        </MenuItem>
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/affiliations")}>
                          Affiliations
                        </MenuItem>
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/administration")}>
                          Administration
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </ListItem>

        <ListItem
          onMouseEnter={handleToggle}
          onMouseLeave={handleToggle}
          className={classes.listItem}
        >
          <div>
            <span
              href="#"
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              style={{
                padding: 15,
                fontWeight: "bold",
                alignItems: "center",
                cursor: "pointer",
                color: "black",
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
              }}
              onMouseEnter={(e) => (
                (e.target.style.color = "#ffffff"),
                (e.target.style.backgroundColor = "#fdc632")
              )}
              onMouseLeave={(e) => (
                (e.target.style.color = "#000000"),
                (e.target.style.backgroundColor = "#ffffff")
              )}
            >
              Academics
            </span>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem disabled>UG Courses</MenuItem>
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/bca")}>
                          BCA
                        </MenuItem>
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/ba")}>
                          BA
                        </MenuItem>
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/bcom")}>
                          BCOM
                        </MenuItem>
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/bsc")}>
                          BSC (PCM-CBZ)
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </ListItem>

        <ListItem
          onMouseEnter={handleAdmissionToggle}
          onMouseLeave={handleAdmissionToggle}
          className={classes.listItem}
        >
          <div>
            <span
              ref={admissionRef}
              aria-controls={admissionOpen ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              style={{
                padding: 15,
                fontWeight: "bold",
                alignItems: "center",
                cursor: "pointer",
                color: "black",
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
              }}
              onMouseEnter={(e) => (
                (e.target.style.color = "#ffffff"),
                (e.target.style.backgroundColor = "#fdc632")
              )}
              onMouseLeave={(e) => (
                (e.target.style.color = "#000000"),
                (e.target.style.backgroundColor = "#ffffff")
              )}
            >
              Admission
            </span>
            <Popper
              open={admissionOpen}
              anchorEl={admissionRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleAdmissionClose}>
                      <MenuList
                        id="menu-list-grow"
                        onKeyDown={handleAdmissionListKeyDown}
                      >
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/fee-structure")}
                        >
                          Academic Fee
                        </MenuItem>
                        <a
                          target="_blank"
                          href={pdf}
                          style={{
                            textDecoration: "none",
                            textDecorationColor: "none",
                            color: "black",
                          }}
                          class="button"
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                        >
                          <MenuItem>College Brochure</MenuItem>
                        </a>
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => setOpenModal(true)}
                        >
                          Admission Enquiry
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </ListItem>

        <ListItem
          onMouseEnter={handleCampusToggle}
          onMouseLeave={handleCampusToggle}
          className={classes.listItem}
        >
          <div>
            <span
              ref={campusRef}
              aria-controls={campusOpen ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              style={{
                padding: 15,
                fontWeight: "bold",
                alignItems: "center",
                cursor: "pointer",
                color: "black",
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
              }}
              onMouseEnter={(e) => (
                (e.target.style.color = "#ffffff"),
                (e.target.style.backgroundColor = "#fdc632")
              )}
              onMouseLeave={(e) => (
                (e.target.style.color = "#000000"),
                (e.target.style.backgroundColor = "#ffffff")
              )}
            >
              Campus
            </span>
            <Popper
              open={campusOpen}
              anchorEl={campusRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleCampusClose}>
                      <MenuList
                        id="menu-list-grow"
                        onKeyDown={handleCampusListKeyDown}
                      >
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/library")}
                        >
                          Library
                        </MenuItem>
                        <MenuItem disabled>Laboratories</MenuItem>
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/computer-lab")}
                        >
                          Computer Lab
                        </MenuItem>
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/chemistry-lab")}
                        >
                          Chemistry Lab
                        </MenuItem>
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/physics-lab")}
                        >
                          Physics Lab
                        </MenuItem>
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/digital-electronics-lab")}
                        >
                          Digital Electronics Lab
                        </MenuItem>
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/zoology-lab")}
                        >
                          Zoology Lab
                        </MenuItem>
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/botany-lab")}
                        >
                          Botany Lab
                        </MenuItem>
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/lecture-hall")}
                        >
                          Lecture Hall
                        </MenuItem>
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/sports-activities")}
                        >
                          Sports Activities
                        </MenuItem>
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/cultural-activities")}
                        >
                          Cultural Activities
                        </MenuItem>
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/industrial-visit")}
                        >
                          Industrial Visit
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </ListItem>

        <ListItem
          onMouseEnter={handleDepartmentToggle}
          onMouseLeave={handleDepartmentToggle}
          className={classes.listItem}
        >
          <div>
            <span
              ref={departmentRef}
              aria-controls={departmentOpen ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              style={{
                padding: 15,
                fontWeight: "bold",
                alignItems: "center",
                cursor: "pointer",
                color: "black",
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
              }}
              onMouseEnter={(e) => (
                (e.target.style.color = "#ffffff"),
                (e.target.style.backgroundColor = "#fdc632")
              )}
              onMouseLeave={(e) => (
                (e.target.style.color = "#000000"),
                (e.target.style.backgroundColor = "#ffffff")
              )}
            >
              Departments
            </span>
            <Popper
              open={departmentOpen}
              anchorEl={departmentRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleDepartmentClose}>
                      <MenuList
                        id="menu-list-grow"
                        onKeyDown={handleDepartmentListKeyDown}
                      >
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/computer-science")}
                        >
                          Computer Science
                        </MenuItem>
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/science")}
                        >
                          Science
                        </MenuItem>
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/arts")}
                        >
                          Arts
                        </MenuItem>
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/commerce")}
                        >
                          Commerce
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </ListItem>

        <ListItem
          onMouseEnter={handleStudentCornerToggle}
          onMouseLeave={handleStudentCornerToggle}
          className={classes.listItem}
        >
          <div>
            <span
              ref={studentCornerRef}
              aria-controls={studentCornerOpen ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              style={{
                padding: 15,
                fontWeight: "bold",
                alignItems: "center",
                cursor: "pointer",
                color: "black",
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
              }}
              onMouseEnter={(e) => (
                (e.target.style.color = "#ffffff"),
                (e.target.style.backgroundColor = "#fdc632")
              )}
              onMouseLeave={(e) => (
                (e.target.style.color = "#000000"),
                (e.target.style.backgroundColor = "#ffffff")
              )}
            >
              Student Corner
            </span>
            <Popper
              open={studentCornerOpen}
              anchorEl={studentCornerRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleStudentCornerClose}>
                      <MenuList
                        id="menu-list-grow"
                        onKeyDown={handleStudentCornerListKeyDown}
                      >
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/assignment")}
                        >
                          Assignment
                        </MenuItem>
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/previous-paper")}
                        >
                          Previous Paper
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </ListItem>

        <ListItem
          onMouseEnter={handleTPOToggle}
          onMouseLeave={handleTPOToggle}
          className={classes.listItem}
        >
          <div>
            <span
              ref={tpoRef}
              aria-controls={TPOOpen ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              style={{
                padding: 15,
                fontWeight: "bold",
                alignItems: "center",
                cursor: "pointer",
                color: "black",
                fontSize: 16,
                fontFamily: "Rubik, sans-serif",
              }}
              onMouseEnter={(e) => (
                (e.target.style.color = "#ffffff"),
                (e.target.style.backgroundColor = "#fdc632")
              )}
              onMouseLeave={(e) => (
                (e.target.style.color = "#000000"),
                (e.target.style.backgroundColor = "#ffffff")
              )}
            >
              TPO
            </span>
            <Popper
              open={TPOOpen}
              anchorEl={tpoRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleTPOClose}>
                      <MenuList
                        id="menu-list-grow"
                        onKeyDown={handleTPOListKeyDown}
                      >
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/training")}
                        >
                          Training
                        </MenuItem>
                        <MenuItem
                          onMouseEnter={(e) => (
                            (e.target.style.color = "#ffffff"),
                            (e.target.style.backgroundColor = "#fdc632")
                          )}
                          onMouseLeave={(e) => (
                            (e.target.style.color = "#000000"),
                            (e.target.style.backgroundColor = "#ffffff")
                          )}
                          onClick={() => history.push("/placement")}
                        >
                          Placement
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </ListItem>

        <ListItem className={classes.listItem}>
          <span
            style={{
              padding: 15,
              fontWeight: "bold",
              alignItems: "center",
              cursor: "pointer",
              color: "black",
              fontSize: 16,
              // fontFamily: "Rubik, sans-serif",
            }}
            onMouseEnter={(e) => (
              (e.target.style.color = "#ffffff"),
              (e.target.style.backgroundColor = "#fdc632")
            )}
            onMouseLeave={(e) => (
              (e.target.style.color = "#000000"),
              (e.target.style.backgroundColor = "#ffffff")
            )}
            onClick={() => history.push("/alumni")}
          >
            Alumni
          </span>
        </ListItem>

        <ListItem className={classes.listItem}>
          <span
            style={{
              padding: 15,
              fontWeight: "bold",
              alignItems: "center",
              cursor: "pointer",
              color: "black",
              fontSize: 16,
              // fontFamily: "Rubik, sans-serif",
            }}
            onMouseEnter={(e) => (
              (e.target.style.color = "#ffffff"),
              (e.target.style.backgroundColor = "#fdc632")
            )}
            onMouseLeave={(e) => (
              (e.target.style.color = "#000000"),
              (e.target.style.backgroundColor = "#ffffff")
            )}
            onClick={() => history.push("/gallery")}
          >
            Gallery
          </span>
        </ListItem>

        <ListItem className={classes.listItem}>
          <span
            style={{
              padding: 15,
              fontWeight: "bold",
              alignItems: "center",
              cursor: "pointer",
              color: "black",
              fontSize: 16,
              // fontFamily: "Rubik, sans-serif",
            }}
            onMouseEnter={(e) => (
              (e.target.style.color = "#ffffff"),
              (e.target.style.backgroundColor = "#fdc632")
            )}
            onMouseLeave={(e) => (
              (e.target.style.color = "#000000"),
              (e.target.style.backgroundColor = "#ffffff")
            )}
            onClick={() => history.push("/contact-us")}
          >
            Contact Us
          </span>
        </ListItem>

        {/* <ListItem className={classes.listItem}>
          <Button href="/courses" color="transparent" className={classes.navLink}>
            COURSES
          </Button>
        </ListItem> */}

        {/* <ListItem className={classes.listItem}>
          <Button
            href="#"
            color="transparent"
            className={classes.navLink}
          >
            FACILITIES 
          </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button
            href="#"
            color="transparent"
            className={classes.navLink}
          >
            GALLERY 
          </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button
            href="#"
            color="transparent"
            className={classes.navLink}
          >
            FACULTY 
          </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button
            href="#"
            color="transparent"
            className={classes.navLink}
          >
            CONTACT 
          </Button>
        </ListItem> */}

        {/* <ListItem className={classes.listItem}>
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            id="instagram-twitter"
            title="Follow us on twitter"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              href="#"
              target="_blank"
              color="transparent"
              className={classes.navLink}
            >
              <i className={classes.socialIcons + " fab fa-twitter"} />
            </Button>
          </Tooltip>
        </ListItem> */}

        {/* <ListItem className={classes.listItem}>
          <Tooltip
            id="instagram-facebook"
            title="Follow us on facebook"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              color="transparent"
              href="https://www.facebook.com/aishwaryacollegepali?ref=aceWebsite"
              target="_blank"
              className={classes.navLink}
            >
              <i className={classes.socialIcons + " fab fa-facebook"} />
            </Button>
          </Tooltip>
        </ListItem> */}

        {/* <ListItem className={classes.listItem}>
          <Tooltip
            id="instagram-tooltip"
            title="Follow us on instagram"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              color="transparent"
              href="#"
              target="_blank"
              className={classes.navLink}
            >
              <i className={classes.socialIcons + " fab fa-instagram"} />
            </Button>
          </Tooltip>
        </ListItem> */}
      </List>
    }
    </>
  );
};