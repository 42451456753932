import React, { useEffect } from "react";
import classNames from "classnames";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, grayTextColor } from "../../Utils/index";
import CommerceDeptImage from "assets/img/CommerceDept.png";
import prakashComImage from "assets/img/Prakash.jpeg";
import preetiJoshiImage from "assets/img/preetiJoshi.jpg";
import ShwetaKhatriImage from "assets/img/ShwetaKhatri.jpg";

import BhawanaMaheshwariImage from "assets/img/BhawanaMaheshwari.jpeg";
import AnjuSoniImage from "assets/img/AnjuSoni.jpeg";
import FirojImage from "assets/img/firoj.jpg";

const useStyles = makeStyles(styles);

export default function Commerce(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const imageStyle = {
    // borderRadius: 20
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classNames(classes.main)}
        style={{ marginTop: 110, backgroundColor: "white" }}
      >
        <div>
          <div className={classes.container} style={{ padding: 30 }}>
            <h2
              style={{
                fontSize: "30px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
              }}
            >
              Commerce Department
            </h2>

            <div
              style={{ marginTop: 20, marginBottom: 10, textAlign: "center" }}
            >
              <img width={"50%"} height={"50%"} src={CommerceDeptImage} style={imageStyle} />
            </div>

            <div>
              <h2
                style={{
                  fontSize: "30px",
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "bold",
                  color: darkBlueColor,
                  marginBottom: 20,
                  textAlign: "center",
                }}
              >
                Welcome to the Department of Commerce, ACE-Pali
              </h2>

              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                }}
              >
                Department of Commerce at ACE-Pali offers Bachelor of Commerce
                (B.Com) is an undergraduate course aimed at imparting thorough
                practical and conceptual knowledge in a variety of subjects like
                Accounting, Finance, Taxation, Management, Capital market,
                Income tax, Information Technology, Tally and so on. The
                relevance of commerce in terms of the ever- increasing number of
                enrolments and the job opportunities it offers, the department
                is taking creative initiatives to motivate and inspire students
                to produce the best by organizing different seminars, workshops,
                personality development programs in addition to regular
                academics. The Department is also providing extension activities
                to cater to the requirements of the community at large.
              </p>

              <h5
                style={{
                  marginTop: 30,
                  fontSize: 18,
                  fontWeight: "bold",
                  fontFamily: "Rubik, sans-serif",
                  color: darkBlueColor,
                }}
              >
                Area of coverage:
              </h5>

              <p
                style={{
                  color: grayTextColor,
                  fontSize: 16,
                  fontFamily: "Rubik, sans-serif",
                  textAlign: "justify",
                }}
              >
                Business Statistics, Modern Banking, Business Regulatory Frame
                work, Marketing Management, Financial Accounting, Corporate
                Accounting, E-Commerce and General Informatics, Finance &amp;
                Taxation, Financial Management, Cost Accounting, Special
                Accounting, Advertising and Sales promotion, Environment
                Management, Open Stream Open Course, Income Tax-Law and
                Practice, Computer Awareness and Information Technology.
              </p>

              <h2
                style={{
                  fontSize: 25,
                  fontFamily: "Rubik, sans-serif",
                  fontWeight: "bold",
                  color: darkBlueColor,
                  marginBottom: 40,
                  textAlign: "center",
                }}
              >
                Faculties
              </h2>

              <Grid
                container
                spacing={3}
                style={{
                  justifyContent: "space-around",
                }}
              >
                <Grid item xs={12} sm={12} md={3} lg={3} style={{textAlign: 'center'}}>
                  <img width={250} height={250} src={prakashComImage} style={imageStyle} />
                  <h2
                    style={{
                      fontSize: 20,
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: "bold",
                      color: darkBlueColor,
                      marginBottom: -20,
                    }}
                  >
                    Prakash Choudhary
                  </h2>

                  <h2
                    style={{
                      fontSize: 14,
                      fontFamily: "Rubik, sans-serif",
                      color: grayTextColor,
                    }}
                  >
                    (BCOM, MCOM (TAX-GST))
                  </h2>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={3} lg={3} style={{textAlign: 'center'}}>
                  <img width={250} height={250} src={ShwetaKhatriImage} style={imageStyle} />
                  <h2
                    style={{
                      fontSize: 20,
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: "bold",
                      color: darkBlueColor,
                      marginBottom: -20,
                    }}
                  >
                    Sweta Khatri
                  </h2>

                  <h2
                    style={{
                      fontSize: 14,
                      fontFamily: "Rubik, sans-serif",
                      color: grayTextColor,
                    }}
                  >
                    (C.A. FINAL, M.Phil, Accounting)
                  </h2>
                </Grid> */}

                <Grid item xs={12} sm={12} md={3} lg={3} style={{textAlign: 'center'}}>
                  <img width={250} height={250} src={BhawanaMaheshwariImage} style={imageStyle} />
                  <h2
                    style={{
                      fontSize: 20,
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: "bold",
                      color: darkBlueColor,
                      marginBottom: -20,
                    }}
                  >
                    Bhawana Maheshwari
                  </h2>

                  <h2
                    style={{
                      fontSize: 14,
                      fontFamily: "Rubik, sans-serif",
                      color: grayTextColor,
                    }}
                  >
                    (BCOM, MA (ECONOMICS), MCOM (EAFM) , NET.)
                  </h2>
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3} style={{textAlign: 'center'}}>
                  <img width={250} height={250} src={FirojImage} style={imageStyle} />
                  <h2
                    style={{
                      fontSize: 20,
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: "bold",
                      color: darkBlueColor,
                      marginBottom: -20,
                    }}
                  >
                    Firoj Khan
                  </h2>

                  <h2
                    style={{
                      fontSize: 14,
                      fontFamily: "Rubik, sans-serif",
                      color: grayTextColor,
                    }}
                  >
                    (BCOM, MCOM)
                  </h2>
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3} style={{textAlign: 'center'}}>
                  <img width={250} height={250} src={AnjuSoniImage} style={imageStyle} />
                  <h2
                    style={{
                      fontSize: 20,
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: "bold",
                      color: darkBlueColor,
                      marginBottom: -20,
                    }}
                  >
                    Mrs. Anju Soni
                  </h2>

                  <h2
                    style={{
                      fontSize: 14,
                      fontFamily: "Rubik, sans-serif",
                      color: grayTextColor,
                    }}
                  >
                    (BCOM, MCOM)
                  </h2>
                </Grid>


                {/* <Grid item xs={12} sm={12} md={3} lg={3} style={{textAlign: 'center'}}>
                  <img
                    width={250}
                    height={250}
                    src={preetiJoshiImage}
                    style={imageStyle}
                  />
                  <h2
                    style={{
                      fontSize: 20,
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: "bold",
                      color: darkBlueColor,
                      marginBottom: -20,
                    }}
                  >
                    Preeti Joshi
                  </h2>

                  <h2
                    style={{
                      fontSize: 14,
                      fontFamily: "Rubik, sans-serif",
                      color: grayTextColor,
                    }}
                  >
                    (MBA)
                  </h2>
                </Grid> */}
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
