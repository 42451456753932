import React, { useEffect } from "react";
import classNames from "classnames";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { 
  withStyles, 
  makeStyles 
} from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, yelloColor } from "../../Utils/index";
import fee from "assets/img/fee.png";

const useStyles = makeStyles(styles);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: darkBlueColor,
    fontSize: 18,
    fontFamily: 'roboto',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 16,
    fontFamily: 'roboto',
    fontWeight: 500,
    color: darkBlueColor,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {},
  },
}))(TableRow);

export default function Fee(props) {
  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function createData(sno, name, feesOne, feesTwo, FeesThree) {
    return { sno, name, feesOne, feesTwo, FeesThree };
  }

  const bcaRows = [
    createData(1, "ACE FORM & PROSPECTUS FEES", 100, 0, 0),
    createData(2, "ACE REGISTRATION FEES", "1,400", 0, 0),
    createData(3, "IDENTITY CARD", 100, 0, 0),
    createData(4, "BOOK BANK FEES", 800, 800, 800),
    createData(5, "CAUTION FEES", "1,000", 0, 0),
    createData(6, "UNIVERSITY FEES", 850, 850, 850),
    createData(7, "COLLEGE ACTIVITY FEES", 300, 300, 300),
    createData(8, "TUITION FEES", "19,450", "21,050", "22,550"),
    createData(9, "PRACTICAL", "1,000", "1,500", "1,500"),
  ];

  const bcomRows = [
    createData(1, "ACE FORM & PROSPECTUS FEES", 100, 0, 0),
    createData(2, "ACE REGISTRATION FEES", "1,400", 0, 0,),
    createData(3, "IDENTITY CARD", 100, 0, 0),
    createData(4, "BOOK BANK FEES", 800, 800, 800),
    createData(5, "CAUTION FEES", "1,000", 0, 0),
    createData(6, "UNIVERSITY FEES", 850, 850, 850),
    createData(7, "COLLEGE ACTIVITY FEES", 300, 300, 300),
    createData(8, "TUITION FEES", "12,950", "14,050", "14,550"),
    createData(9, "PRACTICAL", 0, 0, 0),
  ];

  const baRows = [
    createData(1, "ACE FORM & PROSPECTUS FEES", 100, 0, 0),
    createData(2, "ACE REGISTRATION FEES", "1,400", 0, 0),
    createData(3, "IDENTITY CARD", 100, 0, 0),
    createData(4, "BOOK BANK FEES", 800, 800, 800),
    createData(5, "CAUTION FEES", "1,000", 0, 0),
    createData(6, "UNIVERSITY FEES", 850, 850, 850),
    createData(7, "COLLEGE ACTIVITY FEES", 300, 300, 300),
    createData(8, "TUITION FEES", "9,450", "10,250", "10,750"),
    createData(9, "PRACTICAL", 800, 800, 800),
  ];

  const bscCbzRows = [
    createData(1, "ACE FORM & PROSPECTUS FEES", 100, 0, 0),
    createData(2, "ACE REGISTRATION FEES", "1,400", 0, 0),
    createData(3, "IDENTITY CARD", 100, 0, 0),
    createData(4, "BOOK BANK FEES", 800, 800, 800),
    createData(5, "CAUTION FEES", "1,000", 0, 0),
    createData(6, "UNIVERSITY FEES", 850, 850, 850),
    createData(7, "COLLEGE ACTIVITY FEES", 300, 300, 300),
    createData(8, "TUITION FEES", "18,450", "20,250", "21,450"),
    createData(9, "PRACTICAL", 3000, 3000, 3000),
  ];

  const bscPcmRows = [
    createData(1, "ACE FORM & PROSPECTUS FEES", 100, 0, 0),
    createData(2, "ACE REGISTRATION FEES", "1,400", 0, 0,),
    createData(3, "IDENTITY CARD", 100, 0, 0),
    createData(4, "BOOK BANK FEES", 800, 800, 800),
    createData(5, "CAUTION FEES", "1,000", 0, 0),
    createData(6, "UNIVERSITY FEES", 850, 850, 850),
    createData(7, "COLLEGE ACTIVITY FEES", 300, 300, 300),
    createData(8, "TUITION FEES", "17,450", "19,150", "20,050"),
    createData(9, "PRACTICAL", 2000, 2000, 2000),
  ];

  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classNames(classes.main)}
        style={{ marginTop: 110, backgroundColor: "white" }}
      >
        <div>
          <div className={classes.container} style={{ padding: 30 }}>
            <h2
              style={{
                fontSize: "30px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
              }}
            >
              Academic Fees Structure (2024-25)
            </h2>

            <h2
              style={{
                fontSize: "30px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
                marginBottom: 40,
                textAlign: 'center'
              }}
            >
              Fees Structure (JNVU-2023)
            </h2>

            <Grid container spacing={5}>
              {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                <div style={{ backgroundColor: darkBlueColor, height: '100%', marginTop: -20 }}>
                  <h1 style={{ paddingTop: 150, fontSize: 40, textAlign: 'center', color: 'white', fontWeight: 'bold', }} >BCA</h1>
                </div>
              </Grid> */}

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        {/* <StyledTableCell align="left">S. No</StyledTableCell> */}
                        <StyledTableCell align="left">Particular <span style={{ color: yelloColor }}>(BCA)</span></StyledTableCell>
                        <StyledTableCell align="center">1st Year</StyledTableCell>
                        <StyledTableCell align="center">2nd Year</StyledTableCell>
                        <StyledTableCell align="center">3rd Year</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bcaRows?.map((row) => (
                        <StyledTableRow key={row.name} style={{ marginTop: 200 }}>
                          {/* <StyledTableCell
                            align="left"
                          >
                            {row.sno}
                          </StyledTableCell> */}
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="left"
                          >
                            {row.name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.feesOne}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {row.feesTwo}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {row.FeesThree}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                      <StyledTableRow style={{ backgroundColor: yelloColor }}>
                          {/* <StyledTableCell
                            align="left"
                            style={{ 
                              fontSize: 18,
                              color: 'white',
                              fontWeight: 'bold'
                            }}
                          >
                            10
                          </StyledTableCell> */}
                          <StyledTableCell
                            component="th"
                            align="left"
                            style={{ 
                              fontSize: 18,
                              color: darkBlueColor,
                              fontWeight: 'bold'
                            }}
                          >
                            TOTAL
                          </StyledTableCell>
                          <StyledTableCell 
                            align="center"
                            style={{ 
                              fontSize: 18,
                              color: darkBlueColor,
                              fontWeight: 'bold'
                            }}
                          >
                            25,000
                          </StyledTableCell>

                          <StyledTableCell 
                            align="center"
                            style={{ 
                              fontSize: 18,
                              color: darkBlueColor,
                              fontWeight: 'bold'
                            }}
                          >
                            24,500
                          </StyledTableCell>

                          <StyledTableCell 
                            align="center"
                            style={{ 
                              fontSize: 18,
                              color: darkBlueColor,
                              fontWeight: 'bold'
                            }}
                          >
                            26,000
                          </StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        {/* <StyledTableCell align="left">S. No</StyledTableCell> */}
                        <StyledTableCell align="left">Particular <span style={{ color: yelloColor }}>(B.COM)</span></StyledTableCell>
                        <StyledTableCell align="center">1st Year</StyledTableCell>
                        <StyledTableCell align="center">2nd Year</StyledTableCell>
                        <StyledTableCell align="center">3rd Year</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bcomRows?.map((row) => (
                        <StyledTableRow key={row.name}>
                          {/* <StyledTableCell
                            align="left"
                          >
                            {row.sno}
                          </StyledTableCell> */}
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="left"
                          >
                            {row.name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.feesOne}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {row.feesTwo}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {row.FeesThree}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                      <StyledTableRow style={{ backgroundColor: yelloColor }}>
                          {/* <StyledTableCell
                            align="left"
                            style={{ 
                              fontSize: 18,
                              color: 'white',
                              fontWeight: 'bold'
                            }}
                          >
                            10
                          </StyledTableCell> */}
                          <StyledTableCell
                            component="th"
                            align="left"
                            style={{ 
                              fontSize: 18,
                              color: darkBlueColor,
                              fontWeight: 'bold'
                            }}
                          >
                            TOTAL
                          </StyledTableCell>
                          <StyledTableCell 
                            align="center"
                            style={{ 
                              fontSize: 18,
                              color: darkBlueColor,
                              fontWeight: 'bold'
                            }}
                          >
                            17,500
                          </StyledTableCell>

                          <StyledTableCell 
                            align="center"
                            style={{ 
                              fontSize: 18,
                              color: darkBlueColor,
                              fontWeight: 'bold'
                            }}
                          >
                            16,000
                          </StyledTableCell>

                          <StyledTableCell 
                            align="center"
                            style={{ 
                              fontSize: 18,
                              color: darkBlueColor,
                              fontWeight: 'bold'
                            }}
                          >
                            16,500
                          </StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        {/* <StyledTableCell align="left">S. No</StyledTableCell> */}
                        <StyledTableCell align="left">Particular <span style={{ color: yelloColor }}>(BA)</span></StyledTableCell>
                        <StyledTableCell align="center">1st Year</StyledTableCell>
                        <StyledTableCell align="center">2nd Year</StyledTableCell>
                        <StyledTableCell align="center">3rd Year</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {baRows?.map((row) => (
                        <StyledTableRow key={row.name}>
                          {/* <StyledTableCell
                            align="left"
                          >
                            {row.sno}
                          </StyledTableCell> */}
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="left"
                          >
                            {row.name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.feesOne}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {row.feesTwo}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {row.FeesThree}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                      <StyledTableRow style={{ backgroundColor: yelloColor }}>
                          {/* <StyledTableCell
                            align="left"
                            style={{ 
                              fontSize: 18,
                              color: 'white',
                              fontWeight: 'bold'
                            }}
                          >
                            10
                          </StyledTableCell> */}
                          <StyledTableCell
                            component="th"
                            align="left"
                            style={{ 
                              fontSize: 18,
                              color: darkBlueColor,
                              fontWeight: 'bold'
                            }}
                          >
                            TOTAL
                          </StyledTableCell>
                          <StyledTableCell 
                            align="center"
                            style={{ 
                              fontSize: 18,
                              color: darkBlueColor,
                              fontWeight: 'bold'
                            }}
                          >
                            14,800
                          </StyledTableCell>

                          <StyledTableCell 
                            align="center"
                            style={{ 
                              fontSize: 18,
                              color: darkBlueColor,
                              fontWeight: 'bold'
                            }}
                          >
                            13,000
                          </StyledTableCell>

                          <StyledTableCell 
                            align="center"
                            style={{ 
                              fontSize: 18,
                              color: darkBlueColor,
                              fontWeight: 'bold'
                            }}
                          >
                            13,500
                          </StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        {/* <StyledTableCell align="left">S. No</StyledTableCell> */}
                        <StyledTableCell align="left">Particular <span style={{ color: yelloColor }}>(BSC-CBZ)</span></StyledTableCell>
                        <StyledTableCell align="center">1st Year</StyledTableCell>
                        <StyledTableCell align="center">2nd Year</StyledTableCell>
                        <StyledTableCell align="center">3rd Year</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bscCbzRows?.map((row) => (
                        <StyledTableRow key={row.name}>
                          {/* <StyledTableCell
                            align="left"
                          >
                            {row.sno}
                          </StyledTableCell> */}
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="left"
                          >
                            {row.name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.feesOne}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {row.feesTwo}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {row.FeesThree}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                      <StyledTableRow style={{ backgroundColor: yelloColor }}>
                          {/* <StyledTableCell
                            align="left"
                            style={{ 
                              fontSize: 18,
                              color: 'white',
                              fontWeight: 'bold'
                            }}
                          >
                            10
                          </StyledTableCell> */}
                          <StyledTableCell
                            component="th"
                            align="left"
                            style={{ 
                              fontSize: 18,
                              color: darkBlueColor,
                              fontWeight: 'bold'
                            }}
                          >
                            TOTAL
                          </StyledTableCell>
                          <StyledTableCell 
                            align="center"
                            style={{ 
                              fontSize: 18,
                              color: darkBlueColor,
                              fontWeight: 'bold'
                            }}
                          >
                            26,000
                          </StyledTableCell>

                          <StyledTableCell 
                            align="center"
                            style={{ 
                              fontSize: 18,
                              color: darkBlueColor,
                              fontWeight: 'bold'
                            }}
                          >
                            25,200
                          </StyledTableCell>

                          <StyledTableCell 
                            align="center"
                            style={{ 
                              fontSize: 18,
                              color: darkBlueColor,
                              fontWeight: 'bold'
                            }}
                          >
                            26,400
                          </StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        {/* <StyledTableCell align="left">S. No</StyledTableCell> */}
                        <StyledTableCell align="left">Particular <span style={{ color: yelloColor }}>(BSC-PCM)</span></StyledTableCell>
                        <StyledTableCell align="center">1st Year</StyledTableCell>
                        <StyledTableCell align="center">2nd Year</StyledTableCell>
                        <StyledTableCell align="center">3rd Year</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bscPcmRows?.map((row) => (
                        <StyledTableRow key={row.name}>
                          {/* <StyledTableCell
                            align="left"
                          >
                            {row.sno}
                          </StyledTableCell> */}
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="left"
                          >
                            {row.name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.feesOne}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {row.feesTwo}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {row.FeesThree}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                      <StyledTableRow style={{ backgroundColor: yelloColor }}>
                          {/* <StyledTableCell
                            align="left"
                            style={{ 
                              fontSize: 18,
                              color: 'white',
                              fontWeight: 'bold'
                            }}
                          >
                            10
                          </StyledTableCell> */}
                          <StyledTableCell
                            component="th"
                            align="left"
                            style={{ 
                              fontSize: 18,
                              color: darkBlueColor,
                              fontWeight: 'bold'
                            }}
                          >
                            TOTAL
                          </StyledTableCell>
                          <StyledTableCell 
                            align="center"
                            style={{ 
                              fontSize: 18,
                              color: darkBlueColor,
                              fontWeight: 'bold'
                            }}
                          >
                            24,000
                          </StyledTableCell>

                          <StyledTableCell 
                            align="center"
                            style={{ 
                              fontSize: 18,
                              color: darkBlueColor,
                              fontWeight: 'bold'
                            }}
                          >
                            23,100
                          </StyledTableCell>

                          <StyledTableCell 
                            align="center"
                            style={{ 
                              fontSize: 18,
                              color: darkBlueColor,
                              fontWeight: 'bold'
                            }}
                          >
                            24,000
                          </StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

            {/* <div style={{ marginBottom: 50, marginTop: 30 }}>
              <img width={"100%"} height={"100%"} src={fee} />
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
