import React, { useEffect } from "react";
import classNames from "classnames";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { darkBlueColor, grayTextColor } from "../Utils/index";
import directorImage from "assets/img/director.png";
import NareshPurohitImage from "assets/img/NareshPurohit.jpg";
import varunjiImage from "assets/img/varunji.jpg";
import rajaramImage from "assets/img/rajaram.jpg";
import BhomSinghRathoreImage from "assets/img/BhomSinghRathore.jpg";
import aceLogoImage from "assets/img/AceLogo.png";

const useStyles = makeStyles(styles);

export default function Administration(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageStyle = {
    // borderRadius: 20,
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header
        color="white"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <div
        className={classNames(classes.main)}
        style={{ marginTop: 110, backgroundColor: "white" }}
      >
        <div>
          <div className={classes.container} style={{ padding: 30 }}>
            <h2
              style={{
                fontSize: "30px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: "bold",
                color: darkBlueColor,
              }}
            >
              Administration
            </h2>

            <div
              style={{
                marginTop: 20,
                marginBottom: 70,
                textAlign: "center",
              }}
            >
              <Grid
                container
                spacing={3}
                style={{
                  justifyContent: "space-around",
                }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <img width={250} height={300} src={directorImage} style={imageStyle}/>
                  <h2
                    style={{
                      fontSize: 20,
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: "bold",
                      color: 'red',
                      marginBottom: -20,
                      textAlign: "center",
                    }}
                  >
                    Tajveer Singh Rathore
                  </h2>

                  <h2
                    style={{
                      fontSize: 14,
                      fontFamily: "Rubik, sans-serif",
                      color: grayTextColor,
                      textAlign: "center",
                    }}
                  >
                    (Managing Director)
                  </h2>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={3}
                style={{
                  justifyContent: "space-around",
                }}
              >
                {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                  <img width={250} height={300} src={varunjiImage} style={imageStyle}/>
                  <h2
                    style={{
                      fontSize: 20,
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: "bold",
                      color: "red",
                      marginBottom: -20,
                      textAlign: "center",
                    }}
                  >
                    Varun Chouhan
                  </h2>

                  <h2
                    style={{
                      fontSize: 14,
                      fontFamily: "Rubik, sans-serif",
                      color: grayTextColor,
                      textAlign: "center",
                    }}
                  >
                    (Marketing Executive)
                  </h2>
                </Grid> */}

                {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                  <img width={250} height={300} src={NareshPurohitImage} />
                  <h2
                    style={{
                      fontSize: 20,
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: "bold",
                      color: 'red',
                      marginBottom: -20,
                      textAlign: "center",
                    }}
                  >
                    Naresh Purohit
                  </h2>

                  <h2
                    style={{
                      fontSize: 14,
                      fontFamily: "Rubik, sans-serif",
                      color: grayTextColor,
                      textAlign: "center",
                    }}
                  >
                    (Admission In-Charge)
                  </h2>
                </Grid> */}
              </Grid>

              <Grid
                container
                spacing={3}
                style={{
                  justifyContent: "space-around",
                }}
              >
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <img
                    width={250}
                    height={200}
                    src={aceLogoImage}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={3}
                style={{
                  justifyContent: "space-around",
                }}
              >
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <img
                    width={250}
                    height={300}
                    src={BhomSinghRathoreImage} style={imageStyle}
                  />
                  <h2
                    style={{
                      fontSize: 20,
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: "bold",
                      color: 'red',
                      marginBottom: -20,
                      textAlign: "center",
                    }}
                  >
                    Bhom Singh Rathore
                  </h2>

                  <h2
                    style={{
                      fontSize: 14,
                      fontFamily: "Rubik, sans-serif",
                      color: grayTextColor,
                      textAlign: "center",
                    }}
                  >
                    (Network Administrator)
                  </h2>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <img width={250} height={300} src={rajaramImage} style={imageStyle}/>
                  <h2
                    style={{
                      fontSize: 20,
                      fontFamily: "Rubik, sans-serif",
                      fontWeight: "bold",
                      color: 'red',
                      marginBottom: -20,
                      textAlign: "center",
                    }}
                  >
                    Rajaram
                  </h2>

                  <h2
                    style={{
                      fontSize: 14,
                      fontFamily: "Rubik, sans-serif",
                      color: grayTextColor,
                      textAlign: "center",
                    }}
                  >
                    (Office In-charge)
                  </h2>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
